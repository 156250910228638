import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface StartState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: StartState = {
    status: 'idle',
    error: null,
    data: null,
};


// Start Game 
export const startGame = createAsyncThunk('start/startGame', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/start`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// End Game 
export const endGame = createAsyncThunk('start/endGame', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/end`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the Start Game  slice
const StartGameSlices = createSlice({
    name: 'start',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(startGame.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(startGame.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(startGame.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(endGame.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(endGame.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(endGame.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectStartGame = (state: any) => state.start.data;
export const selectStartStatus = (state: any) => state.start?.status;
export default StartGameSlices.reducer;