import React, { FC } from 'react';
import LoginForm from '../../components/organisms/LoginForm/LoginForm';

const LoginPage: FC = () => {
    return (
        <>
            <LoginForm />
        </>
    )
}

export default LoginPage;