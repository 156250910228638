import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

// Define types for your state and API responses
interface MarketMayhemState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    game_id: number | null;
    marketMayhemId: number | null;
    respondMessage: string | null
}

interface MarketMayhemPayload {
    gameId: number
    stockIds: any[];
    percentage: any[];
}

interface RespondMarketMayhemPayload {
    marketMayhemId: number
    stocks: any[];
}

// Define the initial state
const initialState: MarketMayhemState = {
    status: 'idle',
    error: null,
    data: null,
    game_id: null,
    marketMayhemId: null,
    respondMessage: null
};


// Create Market Mayhem
export const CreateMarketMayhem = createAsyncThunk(
    'market/CreateMarketMayhem',
    async ({ stockIds, gameId, percentage }: MarketMayhemPayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/api/marketMayhem/game/${gameId}`, {
                stock_ids: stockIds,
                percentage: percentage
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);


// getMarket Mayhem 
export const getMarketMayhem = createAsyncThunk('market/getMarketMayhem', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/marketMayhem/game/${gameId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Respond Market Mayhem
export const RespondMarketMayhem = createAsyncThunk(
    'market/RespondMarketMayhem',
    async ({ stocks, marketMayhemId }: RespondMarketMayhemPayload, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/api/marketMayhem/${marketMayhemId}/respond`, {
                stocks: stocks,
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

// Averagre Market Mayhem
export const AveragreMarketMayhem = createAsyncThunk(
    'market/AveragreMarketMayhem',
    async (marketMayhemId: number, { rejectWithValue }) => {
        try {
            const response = await apiClient.post(`${BASE_URL}/api/marketMayhem/${marketMayhemId}/average`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);



// Create the Market mayhem slice
const MarketMayhemSlice = createSlice({
    name: 'market',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(CreateMarketMayhem.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(CreateMarketMayhem.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.game_id = action.payload.data.game_id;
                state.marketMayhemId = action.payload.data
                state.error = null;
            })
            .addCase(CreateMarketMayhem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(getMarketMayhem.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getMarketMayhem.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.marketMayhemId = action.payload.data.id
                state.error = null;
            })
            .addCase(getMarketMayhem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RespondMarketMayhem.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(RespondMarketMayhem.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.message;
                state.respondMessage = action.payload.message
                state.error = null;
            })
            .addCase(RespondMarketMayhem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectMarketMayhem = (state: any) => state.market.data;
export const selectMarketMayhemStatus = (state: any) => state.market?.status;
export const selectMarketMayhemError = (state: any) => state.market?.error?.error?.message;
export const selectmarketMayhemId = (state: any) => state.market?.marketMayhemId;
export const selectRespondMarketMayhem = (state: any) => state.market?.respondMessage;




export default MarketMayhemSlice.reducer;