import React, { FC } from 'react';
import './Loader.scss'

interface LoaderProps {
  height?: string,
  white?: any
}

const Loader: FC<LoaderProps> = (props) => {
  const { height, white } = props
  return (
    <div className='loader-container' style={{ height: height }}>
      <div>
        <div className={`${white ? 'lds1-ellipsis' : 'lds-ellipsis'}`}>
          <div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}

export default Loader