// export const formatPrice = (price: any) => {
//     if (!price || isNaN(Number(price))) {
//         return '';
//     }
//     const parsedPrice = parseFloat(price);
//     const formattedPrice = Math.floor(parsedPrice).toLocaleString('en-IN');

//     // Keep two decimal places if it's a floating number
//     return parsedPrice % 1 === 0 ? formattedPrice : formattedPrice + '';
// };

export const formatPrice = (price: any) => {
    if (!price || isNaN(Number(price))) {
        return '';
    }

    const parsedPrice = parseFloat(price);

    // Rounding logic
    const roundedPrice = parsedPrice % 1 >= 0.5 ? Math.ceil(parsedPrice) : Math.floor(parsedPrice);

    // Format the number using the 'en-IN' locale
    const formattedPrice = roundedPrice.toLocaleString('en-IN');

    return formattedPrice;
};