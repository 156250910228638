import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface StockState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any[];
    mm_exist: number | null
}

interface UpdatePayload {
    stock_id: number,
    operation: any,  // '+' and '-' are valid
    value: number,
    gameId: number
}

// Define the initial state
const initialState: StockState = {
    status: 'idle',
    error: null,
    data: [],
    mm_exist: null
};


// getProfile 
export const getAllStock = createAsyncThunk('stock/getAllStock', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/stocks`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// UpdateGame Setting
export const updateStock = createAsyncThunk('stock/updateStock', async ({ stock_id, operation, value, gameId }: UpdatePayload, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/stocks`, {
            stock_id: stock_id,
            operation: operation,
            value: value,
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Payday
export const payday = createAsyncThunk('stock/payday', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.put(`${BASE_URL}/api/game/${gameId}/payday`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Create the profile slice
const StockSlices = createSlice({
    name: 'stock',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllStock.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAllStock.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.mm_exist = action.payload.data.is_mm_exist
                state.error = null;
            })
            .addCase(getAllStock.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(updateStock.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateStock.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { stock_id, updatedPrice } = action.payload;
                if (Array.isArray(state.data)) {
                    const existingStock = state.data.find((stock: any) => stock.id === stock_id);
                    if (existingStock) {
                        existingStock.current_price = updatedPrice; // Update the price
                    }
                } else {
                    // If state.data is not an array, replace it with the updated stock
                    state.data = [action.payload]; // Wrap the response in an array
                }
            })
            .addCase(updateStock.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(payday.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(payday.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(payday.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllStock = (state: any) => state.stock.data;
export const selectStockStatus = (state: any) => state.stock?.status;
export const selectStockError = (state: any) => state.stock?.error;
export const selectMMExist = (state: any) => state.stock.mm_exist;

export default StockSlices.reducer;