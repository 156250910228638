import React, { useEffect, useState } from 'react'
import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { getProfile, selectAllProfile, selectProfileStatus } from '../../../redux/Slices/ProfileSlice';
import { updateProfile } from '../../../redux/Slices/avatarsSlice';
import { message } from 'antd';
import BackButton from '../../atoms/BackButton/BackButton'
import Button from '../../atoms/Button/Button';
import AccountContainer from '../../molecules/AccountContainer/AccountContainer';
import ProfileAvatars from '../../molecules/profileAvatars/profileAvatars';
import './AccountDetailsScreen.scss'

const AccountDetailsScreen = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [openAvatar, setOpenAvatar] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selcAvatarId, setSelcAvatarId] = useState<number | undefined>();
    const [selcAvatarUrl, setSelcAvatarUrl] = useState<string>('')
    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const profile = useSelector(selectAllProfile);
    const profileStatus = useSelector(selectProfileStatus);

    useEffect(() => {
        dispatch(getProfile()); // Dispatch Get Profile action
    }, [dispatch])

    // Profile status loading state
    useEffect(() => {
        if (profileStatus === 'loading') {
            setLoading(true);
        } else if (profileStatus === 'succeeded') {
            setLoading(false);
        }
    }, [profileStatus]);

    const handleBackButton = () => {
        navigate(-1)
    }

    const handleAddProfile = async () => {
        setLoading(true);
        try {
            await dispatch(updateProfile({ username: name ? name : profile.name, email: email ? email : profile.email, avatarId: selcAvatarId })).unwrap();
            setLoading(false);
            message.success('User profile updated successfully.');
        } catch (error: any) {
            message.error(error.error.message);
            setErrorMessage(error.error.message);
        }
        setLoading(false);
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'name') setName(value);
        if (name === 'email') setEmail(value);
    };
    const handleAvatar = (avatar: { id: number; image: string }) => {
        setSelcAvatarId(avatar.id);
        setSelcAvatarUrl(avatar.image);
        setOpenAvatar(false);
    };

    return (
        <div>
            <div className='account-heading'>
                <BackButton onClick={handleBackButton} buttonHeading='Player profile' className='heading-bckbtn-text font-bagel' />
            </div>
            {/* <div className='heading-bckbtn-acc-text font-bagel'>Account Details</div> */}
            <div className='flex login-container' style={{ height: "100vh", paddingTop: "30px" }}>
                {window.innerWidth > 750 && <img src={image.WEBLOGO} className='login-bg-img' draggable="false"
                    onContextMenu={(e) => e.preventDefault()} />}
                <div className='flex login-upper-container' style={{ gap: "10px" }}>
                    <img
                        src={selcAvatarUrl || profile?.profile_image || image.INVESTORAVATAR}
                        className="account-profile"
                        onClick={() => setOpenAvatar(true)}
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    <AccountContainer profile={profile} loading={loading} handleInputChange={handleInputChange} name={name} email={email} />
                    <div className='updateProfile-btn'>
                        <Button onClick={handleAddProfile} buttonTitle='Update Info' width='200px' />
                    </div>
                </div>

            </div>
            {openAvatar && <ProfileAvatars openAvatar={openAvatar} setOpenAvatar={setOpenAvatar} loading={loading} handleAvatar={handleAvatar} />}

        </div>
    )
}

export default AccountDetailsScreen