import React, { ChangeEvent, FC } from 'react';
import './Input.scss'
import { IoMdInformationCircleOutline } from 'react-icons/io';

interface InputProps {
    label: string;
    placeholder: string;
    type: string;
    id: string;
    name: string;
    value: string | number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    info?: string;
    ShowInfo?: boolean;
    classNameInfo?: string
}

const Input: FC<InputProps> = (props) => {
    const { label, placeholder, name, type, id, disabled, value, onChange, info, ShowInfo, classNameInfo } = props
    return (
        <div>
            <div className='input-instruct'>
                <div className='input-label'>{label}</div>
                {ShowInfo && <div className="get-help-addOn-hover">
                    <div className="help-image-logo" > <IoMdInformationCircleOutline cursor='pointer' /></div>
                    <div className={classNameInfo}>{info}</div>
                </div>}
            </div>
            <input
                className={`search-input`}
                placeholder={placeholder}
                name={name}
                type={type}
                id={id}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onWheel={(e: any) => e.target.blur()}
            />
        </div>
    )
}

export default Input