import React, { FC } from 'react';
import './Button.scss';
import { IoMdInformationCircleOutline } from 'react-icons/io';

interface ButtonProps {
    onClick: VoidFunction,
    buttonTitle: string,
    width?: any,
    className?: any,
    showInfo?: boolean,
    info?: string
}

const Button: FC<ButtonProps> = (props) => {
    const { onClick, buttonTitle, width, className, showInfo, info } = props
    return (
        <div className='border-1'>
            <div className='border-2' onClick={onClick}>
                <div className={`${className} button-container`} style={{ width: width }}>
                    <div className='button-text'>{buttonTitle}</div>
                </div>
            </div>
            {showInfo && <div className="get-help-addOn-hover">
                <div className="get-help-add-on-hover">{info}</div>
            </div>}
        </div>
    )
}

export default Button