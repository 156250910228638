import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface TradeState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

interface TradePayload {
    stock_id: number,
    trade_type: number,   // 0 - buy, 1 - sell
    quantity: number,
    gameId: number
}

// Define the initial state
const initialState: TradeState = {
    status: 'idle',
    error: null,
    data: null,
};


// Update Trade 
export const updateTrade = createAsyncThunk('trade/updateTrade', async ({ stock_id, trade_type, quantity, gameId }: TradePayload, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/stocks/trade`, {
            stock_id: stock_id,
            trade_type: trade_type,
            quantity: quantity
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the Trade slice
const tradeSlices = createSlice({
    name: 'trade',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTrade.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateTrade.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(updateTrade.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllTrade = (state: any) => state.trade.data;
export const selectTradeStatus = (state: any) => state.trade?.status;
export default tradeSlices.reducer;