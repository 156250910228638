import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface TranscState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: TranscState = {
    status: 'idle',
    error: null,
    data: null,
};

// getTransaction 
export const getAllTransaction = createAsyncThunk('transc/getAllTransaction', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/transactions`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Create the Transaction slice
const TransactionSlices = createSlice({
    name: 'transc',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTransaction.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAllTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(getAllTransaction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllTransc = (state: any) => state.transc.data;
export const selectTranscStatus = (state: any) => state.transc?.status;
export const selectTranscError = (state: any) => state.transc?.error;

export default TransactionSlices.reducer;