import React, { FC } from 'react'
import { image } from '../../../app/utils/common/image';
import './BackButton.scss';

interface ButtonProps {
    onClick: VoidFunction,
    buttonHeading?: string,
    className?: string,
    back_class?: string
}

const BackButton: FC<ButtonProps> = (props) => {
    const { onClick, buttonHeading, className, back_class } = props;
    return (
        <div style={{ position: "relative", width: "100%", zIndex: "100" }} >
            <div className={`back-button ${back_class}`}>
                <div>
                    <img src={image.BACKBUTTON} onClick={onClick} />
                </div>
                <div>
                    <div className={className}>{buttonHeading}</div>
                </div>
            </div>
        </div>
    )
}

export default BackButton