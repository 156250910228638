import React, { useEffect, useRef, useState, } from 'react'
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { JoinGame, selectJoinGameStatus, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import BackButton from '../../atoms/BackButton/BackButton'
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import './ShowDownScreen.scss';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { image } from '../../../app/utils/common/image';

const ShowDownScreen = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [code, setCode] = useState<string[]>(["", "", "", ""]);

    const navigate = useNavigate()
    const inputsRef = useRef<any>([]);
    const dispatch: AppDispatch = useDispatch();
    const joinGameStatus = useSelector(selectJoinGameStatus);
    const roleId = useSelector(selectRoleId)
    const joinRoleId = useSelector(selectRoleJoinId);

    const handleCodeButton = async () => {
        const codeString = code.join('');
        if (!codeString) {
            setErrorMessage('Please enter the Valid Code');
            return;
        }
        await dispatch(JoinGame({ code: codeString }))
            .then((action: any) => {
                const { success, error } = action.payload
                if (success) {
                    if (action.payload.data.game_status === 1) {
                        navigate('/showdown-lobby');
                    } else if (action.payload.data.game_status === 2) {
                        navigate('/stock-prices');
                    } else if (action.payload.data.game_status === 2) {
                        navigate('/join-game')
                    } else {
                        navigate('/join-game')
                    }
                } else if (error) {
                    const errorMsg = error || 'Failed to join the game';
                    if (error.message === "User already in this board") {
                        if (joinRoleId === 2 && roleId === 2) {
                            navigate('/stock-prices');
                        } else {
                            navigate('/showdown-lobby');
                        }
                    } else {
                        setErrorMessage(errorMsg?.message);
                    }
                }
            });
    };

    // Handle backspace navigation
    const handleKeyDown = (e: any, index: number) => {
        if (e.key === "Backspace" && code[index] === "" && index > 0) {
            inputsRef.current[index - 1].focus();
        }
    };

    useEffect(() => {
        if (joinGameStatus === 'succeeded') {
            setLoading(false);
        } else if (joinGameStatus === 'loading') {
            setLoading(true);
        } else if (joinGameStatus === 'failed') {
            setLoading(false);
        }
    }, [joinGameStatus]);

    // Handle code input change
    const handleInputChange = (e: any, index: number) => {
        const newCode = [...code];
        newCode[index] = e.target.value;
        setCode(newCode);
        setErrorMessage('');
        if (e.target.value !== "" && index < inputsRef.current.length - 1) {
            inputsRef.current[index + 1].focus();
        }
    };
    const handleBackButton = () => {
        navigate('/landing')
    }


    return (
        <div>
            <div className='account-heading'>
                <BackButton onClick={handleBackButton} />
            </div>
            <div className='flex login-container showDown-upper' style={{ height: "100vh" }}>
                {/* <div className='main-heading font-bagel showDown-heading' >The <br /> Showdown</div> */}
                <img src={image.SHOWDOWNWHITE} className='showdown-image' />
                <div className='flex login-upper-container '>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='showDown-dimension flex'>
                                            <div className='showDown-container'>

                                                <div style={{ display: "flex" }}>
                                                    <div className='code-label'>Enter your board code
                                                        <span className="get-help-addOn-hover" >
                                                            <span className="help-image-logo" > <IoMdInformationCircleOutline cursor='pointer' style={{ marginBottom: "-5px" }} /></span>
                                                            <span className="get-help-code-hover" style={{ left: "-154px" }}>Find the unique board code in the manual, share it with all the players, and use it to enter the game lobby</span>
                                                        </span>
                                                    </div>

                                                </div>
                                                {/* <div className='code-label'>Enter your board code</div> */}
                                                {!loading ? <div>
                                                    <div className='code-container-input'>
                                                        {code.map((digit, index) => (
                                                            <input
                                                                key={index}
                                                                className='search-input referral-input'
                                                                placeholder='-'
                                                                type="tel"
                                                                id="phone-no"
                                                                name="phone-no"
                                                                maxLength={1}
                                                                value={digit}
                                                                onChange={(e) => handleInputChange(e, index)}
                                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                                ref={(el) => (inputsRef.current[index] = el)}
                                                                onWheel={(e: any) => e.target.blur()}
                                                            />
                                                        ))}
                                                    </div>
                                                    {errorMessage && < div className="error-message">{errorMessage}</div>}
                                                </div> : <Loader height={'70px'} />}
                                                <div className='continue-btn'>
                                                    <Button onClick={handleCodeButton} buttonTitle='Submit & Join Lobby' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default ShowDownScreen