import axios, { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { refreshAccessToken } from '../../redux/Slices/authSlice';
import { BASE_URL } from './axiosInstance';

const apiClient = axios.create({
    baseURL: BASE_URL,
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

export const setupAxiosInterceptors = (dispatch: any) => {
    apiClient.interceptors.request.use(
        async (config: InternalAxiosRequestConfig) => {
            const accessToken = localStorage.getItem('access_token');
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );

    apiClient.interceptors.response.use(
        (response: AxiosResponse) => response,
        async (error: AxiosError) => {
            const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise<string>((resolve: any, reject) => {
                        failedQueue.push({ resolve, reject });
                    }).then((token) => {
                        if (originalRequest.headers) {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                        }
                        return apiClient(originalRequest);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise((resolve, reject) => {
                    dispatch(refreshAccessToken())
                        .then(({ payload }: any) => {
                            const { access_token } = payload.data;
                            localStorage.setItem('access_token', access_token);
                            apiClient.defaults.headers['Authorization'] = `Bearer ${access_token}`;
                            if (originalRequest.headers) {
                                originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
                            }
                            processQueue(null, access_token);
                            resolve(apiClient(originalRequest));
                        })
                        .catch((err: any) => {
                            processQueue(err, null);
                            localStorage.clear(); // Clear local storage to remove tokens
                            window.location.href = '/login'; // Redirect to login page
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }

            return Promise.reject(error);
        }
    );
};

export { apiClient };