import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface ShowDownState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: ShowDownState = {
    status: 'idle',
    error: null,
    data: null,
};

// getTransaction 
export const getAllShowDown = createAsyncThunk('showdwn/getAllShowDown', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/showdown`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// Create the Transaction slice
const ShowDownSlices = createSlice({
    name: 'showdwn',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllShowDown.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAllShowDown.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(getAllShowDown.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllShowDown = (state: any) => state.showdwn.data;
export const selectShowDownStatus = (state: any) => state.showdwn?.status;
export const selectShowDownError = (state: any) => state.showdwn?.error;

export default ShowDownSlices.reducer;