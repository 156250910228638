import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface GameSettingState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
    tradeDur: number | null;
}

interface GameSettingPayload {
    startingFunds: number,
    paydayIncrement: number,
    tradePhaseDuration: number,
    gameId: number;
}

interface GameOrderBookProps {
    orderbook_status: any,
    gameId: number
}


// Define the initial state
const initialState: GameSettingState = {
    status: 'idle',
    error: null,
    data: null,
    tradeDur: null
};


// getGameSetting 
export const getGameSetting = createAsyncThunk('setting/getGameSetting', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/settings`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// UpdateGame Setting
export const updateGameSetting = createAsyncThunk('setting/updateGameSetting', async ({ startingFunds, paydayIncrement, tradePhaseDuration, gameId }: GameSettingPayload, { rejectWithValue }) => {
    try {
        const response = await apiClient.post(`${BASE_URL}/api/game/${gameId}/settings`, {
            starting_funds: startingFunds,
            payday_increment: paydayIncrement,
            trade_phase_duration: tradePhaseDuration,
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// UpdateGame Setting
export const updateGameOrderBook = createAsyncThunk('setting/updateGameOrderBook', async ({ orderbook_status, gameId }: GameOrderBookProps, { rejectWithValue }) => {
    try {
        const response = await apiClient.put(`${BASE_URL}/api/game/${gameId}/update/orderbook`, {
            orderbook_status: orderbook_status,
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the GameSetting slice
const GameSettingsSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGameSetting.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getGameSetting.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.tradeDur = action.payload.data?.trade_phase_duration
                state.error = null;
            })
            .addCase(getGameSetting.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(updateGameSetting.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateGameSetting.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;                
                state.error = null;
            })
            .addCase(updateGameSetting.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(updateGameOrderBook.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(updateGameOrderBook.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.error = null;
            })
            .addCase(updateGameOrderBook.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllGameSetting = (state: any) => state.setting?.data;
export const selectGameSettingStatus = (state: any) => state.setting?.status;
export const selectGameSettingError = (state: any) => state.setting?.error;
export const selectTradeDur = (state: any) => state.setting?.tradeDur;

export default GameSettingsSlice.reducer;