import React, { FC } from 'react';
import { image } from '../../../app/utils/common/image';
import Button from '../../atoms/Button/Button';
import Loader from '../../atoms/Loader/Loader';
import './SetupSettingContainer.scss'
import { formatPrice } from '../../atoms/FormatPrice';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import Input from '../../atoms/Input/Input';

interface SetupSettingContainerProps {
    editSettings: boolean,
    setEditSettings: React.Dispatch<React.SetStateAction<boolean>>,
    gameSetting: any,
    loading: boolean
}
const SetupSettingContainer: FC<SetupSettingContainerProps> = (props) => {
    const { setEditSettings, gameSetting, loading } = props
    return (
        <div className='game-dimension flex justify-setup'>
            {!loading ? <div className='setting-container'>
                <div className='setting-game'>
                    {/* <div>
                        <img src={image.RIGHTARROW} className='right-arrow' />
                    </div> */}
                    <div style={{ width: "90%" }}>
                        {/* <div>Starting Funds <span className="get-help-addOn-hover" >
                            <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} /></span>
                            <span className="get-help-add-on-hover get-help-cash">Your starting Cash Balance </span>
                        </span>:</div>
                        <div>INR {formatPrice(gameSetting?.starting_funds)}</div> */}
                        <Input
                            label='Starting Funds'
                            placeholder='Enter Starting Funds'
                            type="any"
                            id="startingFund"
                            name="startingFund"
                            value={`INR ${formatPrice(gameSetting?.starting_funds)}`}
                            onChange={() => { }}
                            disabled
                            ShowInfo
                            info='Your starting Cash Balance'
                            classNameInfo="get-help-add-on-hover get-help-cash" />
                    </div>

                </div>
                <div className='setting-game'>
                    {/* <div>
                        <img src={image.RIGHTARROW} className='right-arrow' />
                    </div> */}
                    <div style={{ width: "90%" }}>
                        {/* <div>Payday Increments<span className="get-help-addOn-hover" >
                            <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} /></span>
                            <span className="get-help-setup-hover">Cash added to your balance each time you land on a Payday tile (three times in total)</span>
                        </span>: </div>
                        <div>INR {formatPrice(gameSetting?.payday_increment)}</div> */}
                        <Input
                            label='Payday Increments'
                            placeholder='Enter Payday Increments:'
                            type="any"
                            id="payDay"
                            name="payDay"
                            value={`INR ${formatPrice(gameSetting?.payday_increment)}`}
                            onChange={() => { }}
                            disabled
                            ShowInfo
                            info='Cash added to your balance each time you land on a Payday tile (three times in total)'
                            classNameInfo='get-help-code-hover' />
                    </div>
                </div>
                <div className='setting-game'>
                    {/* <div>
                        <img src={image.RIGHTARROW} className='right-arrow' />
                    </div> */}
                    <div style={{ width: "90%" }}>
                        {/* <div>Trade Phase Round <span className="get-help-addOn-hover" >
                            <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} /></span>
                            <span className="get-help-setup-hover" style={{ height: "46px" }}>The time limit for each trading round. Investors can buy or sell only during this window once the broker starts the trade</span>
                        </span>:</div>
                        <div>{gameSetting?.trade_phase_duration} Seconds</div> */}
                        <Input
                            label='Trade Phase Round'
                            placeholder='Enter Trade Phase Round'
                            type="tel"
                            id="tradeDuration"
                            name="tradeDuration"
                            value={`${gameSetting?.trade_phase_duration} Seconds`}
                            onChange={() => { }}
                            disabled
                            ShowInfo
                            info='The time limit for each trading round. Once the broker starts the trade phase, investors can buy or sell during this window only'
                            classNameInfo='get-help-code-hover h-code' />
                    </div>
                </div>
                {/* <div className='setting-game'>
                    <div>
                        <img src={image.RIGHTARROW} className='right-arrow' />
                    </div>
                    <div>
                        <div>Oderbook:</div>
                        <div>{gameSetting?.orderbook_status === 1 ? 'Active' : 'Inactive'}</div>
                    </div>
                </div> */}
            </div> : <div className="flex items-center justify-center">
                <Loader height={'210px'} />
            </div>}
            <Button buttonTitle='Edit Settings' onClick={() => { setEditSettings(true) }} />
        </div>
    )
}

export default SetupSettingContainer