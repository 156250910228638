import React, { FC } from 'react'
import Button from '../../atoms/Button/Button'
import { Select } from 'antd';
import { payDayOptions, startingFundsOptions, tradePhaseOptions } from '../../../app/utils/constants/Options';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Input from '../../atoms/Input/Input';
import { formatPrice } from '../../atoms/FormatPrice';
const { Option } = Select;

interface ConfirmSetupProps {
    startingFund: number | undefined;
    payDay: number | undefined;
    tradeDuration: number | undefined;
    gameSetting: any;
    setEditSettings: React.Dispatch<React.SetStateAction<boolean>>;
    handleConfirmSettings: any,
    handleInputChange: any,
    errorMessage: any
}

const ConfirmSetup: FC<ConfirmSetupProps> = (props) => {
    const { startingFund, payDay, tradeDuration, gameSetting, handleConfirmSettings, handleInputChange, errorMessage } = props;

    return (
        <div className='game-dimension flex'>
            <div>
                {/* <Input
                    label='Starting Funds'
                    placeholder='Enter Starting Funds'
                    type="any"
                    id="startingFund"
                    name="startingFund"
                    value={formatPrice(startingFund !== undefined ? startingFund : formatPrice(gameSetting?.starting_funds)) || ''}
                    onChange={(e) => handleInputChange('startingFund', e.target.value)}
                    ShowInfo
                    info='Your starting Cash Balance'
                    classNameInfo="get-help-add-on-hover" /> */}
                <SelectInput
                    label='Starting Funds'
                    value={startingFund || gameSetting?.starting_funds || 500000}
                    onChange={(value) => handleInputChange('startingFund', value)}
                    options={startingFundsOptions}
                    ShowInfo
                    info='Your starting Cash Balance'
                    classNameInfo="get-help-add-on-hover get-help-cash"
                    dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                    optionStyle={{ color: '#1C4384', fontWeight: '700' }}
                />
                {errorMessage === '"starting_funds" must be greater than or equal to 500000' && < div className="error-message">Starting Funds must be greater than or equal to 5,00,000</div>}
                {errorMessage === '"starting_funds" must be less than or equal to 1000000' && < div className="error-message">Starting Funds must be less than or equal to 10,00,000</div>}

            </div>
            <div>
                {/* <Input
                    label='Payday Increments'
                    placeholder='Enter Payday Increments:'
                    type="any"
                    id="payDay"
                    name="payDay"
                    value={formatPrice(payDay !== undefined ? payDay : formatPrice(gameSetting?.payday_increment)) || ''}
                    onChange={(e) => handleInputChange('payDay', e.target.value)}
                    ShowInfo
                    info='Cash added to your balance each time you land on a Payday tile (three times in total).'
                    classNameInfo='get-help-code-hover' /> */}

                <SelectInput
                    label='Payday Increments'
                    value={payDay || gameSetting?.payday_increment || 100000}
                    onChange={(value) => handleInputChange('payDay', value)}
                    options={payDayOptions}
                    ShowInfo
                    info='Cash added to your balance each time you land on a Payday tile (three times in total)'
                    classNameInfo='get-help-code-hover'
                    dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                    optionStyle={{ color: '#1C4384', fontWeight: '700' }}
                />
                {errorMessage === '"payday_increment" must be greater than or equal to 100000' && < div className="error-message">Payday Increments must be greater than or equal to 1,00,000</div>}
                {errorMessage === '"payday_increment" must be less than or equal to 500000' && < div className="error-message">Payday Increments must be less than or equal to 5,00,000</div>}
            </div>

            <div>
                {/* <Input
                    label='Trade Phase Round'
                    placeholder='Enter Trade Phase Round'
                    type="tel"
                    id="tradeDuration"
                    name="tradeDuration"
                    value={tradeDuration !== undefined ? tradeDuration : gameSetting?.trade_phase_duration || ''}
                    onChange={(e) => handleInputChange('tradeDuration', e.target.value)}
                    ShowInfo
                    info='The time limit for each trading round. Investors can buy or sell only during this window once the broker starts the trade..'
                    classNameInfo='get-help-code-hover h-code' /> */}

                <SelectInput
                    label='Trade Phase Round'
                    value={tradeDuration || gameSetting?.trade_phase_duration || 120}
                    onChange={(value) => handleInputChange('tradeDuration', value)}
                    options={tradePhaseOptions}
                    ShowInfo
                    info='The time limit for each trading round. Once the broker starts the trade phase, investors can buy or sell during this window only'
                    classNameInfo='get-help-code-hover h-code'
                    dropdownStyle={{ backgroundColor: '#ffffff', zIndex: '10000' }}
                    optionStyle={{ color: '#1C4384', fontWeight: '700' }}
                />
            </div>

            <Button buttonTitle='Confirm Settings' onClick={handleConfirmSettings} />
        </div>
    )
}

export default ConfirmSetup