import { Modal } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import { getAllAvatars, selectAllAvatars } from '../../../redux/Slices/avatarsSlice';
import './profileAvatars.scss'
import { selectGameId } from '../../../redux/Slices/JoinGameSlice'
import Loader from '../../atoms/Loader/Loader';

interface ProfileAvatarsProps {
    openAvatar: boolean
    setOpenAvatar: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    handleAvatar: any
}

const ProfileAvatars: FC<ProfileAvatarsProps> = ({ setOpenAvatar, openAvatar, loading, handleAvatar }) => {
    const dispatch: AppDispatch = useDispatch();
    const allAvatars = useSelector(selectAllAvatars);

    useEffect(() => {
        dispatch(getAllAvatars());
    }, [dispatch]);

    return (
        <Modal open={openAvatar} footer={false} onCancel={() => { setOpenAvatar(false) }} title='Select Avatar' className='avatar-profile'>
            {!loading ? <div className='chooseAvatars' style={{ paddingTop: "12px" }}>
                {allAvatars?.data?.map((avatar: any) => {
                    return (
                        avatar.id !== 7 && <div key={avatar.id} onClick={() => { handleAvatar(avatar) }} className='avatar-center'>
                            <img src={avatar.image} alt={avatar.name} className='avatar-image' draggable="false"
                                onContextMenu={(e) => e.preventDefault()} />
                        </div>
                    )
                })}
            </div> : <Loader />}
        </Modal>
    )
}

export default ProfileAvatars