import React, { FC } from "react";
import { Modal } from 'antd';
import { image } from "../../../app/utils/common/image";
import './confirmation-modal.scss';
import { confirmModalProps } from "../../../app/utils/interface/confirm";

interface ConfirmModalProps {
    confirmModalProps: confirmModalProps;
}

const ConfirmModal: FC<ConfirmModalProps> = props => {
    const { text, buttons, onPressNegative, onPressPositive, confirmModal, setConfirmModal } = props.confirmModalProps;
    return (
        <div style={{ zIndex: "100000000" }}>
            <Modal
                className="save-modal"
                open={confirmModal}
                onCancel={() => setConfirmModal(false)}
            >
                <div className="save-container">
                    {/* <div className="save-header">
                        <button className="btn-modal close-button" aria-label="Close" onClick={() => setConfirmModal(false)}>
                            <img src={image.BACKBUTTON} className="image_close" />
                        </button>
                    </div> */}
                    <div className="save-main-container">
                        <p>{text}</p>
                        <div className="save-continue-btn">
                            <button className="confirm-continue-btn" onClick={() => onPressNegative()}>{buttons[1]}</button>
                            <button className="btns save-changes" onClick={() => { onPressPositive() }}>{buttons[0]}</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ConfirmModal;