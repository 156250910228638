import React, { FC, useEffect, useState } from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getGameSetting, selectAllGameSetting, selectGameSettingError, selectGameSettingStatus, updateGameSetting } from '../../../redux/Slices/GameSettingSlice';
import { selectGameId } from '../../../redux/Slices/JoinGameSlice';
import { startGame } from '../../../redux/Slices/StartGame';
import Button from '../../atoms/Button/Button';
import ConfirmSetup from '../../molecules/ConfirmSetup/ConfirmSetup';
import SetupSettingContainer from '../../molecules/SetupSettingContainer/SetupSettingContainer';
import './GameSetupScreen.scss';
import BackButton from '../../atoms/BackButton/BackButton';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';

const GameSetupScreen = () => {
    const [editSettings, setEditSettings] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [startingFund, setStartingFund] = useState<number>();
    const [payDay, setPayDay] = useState<number>();
    const [tradeDuration, setTradeDuration] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<any>();

    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const gameSetting = useSelector(selectAllGameSetting);
    const gameSettingStatus = useSelector(selectGameSettingStatus);
    const gameSettingError = useSelector(selectGameSettingError);
    const gameId = useSelector(selectGameId)

    useEffect(() => {
        dispatch(getGameSetting(gameId));
    }, [dispatch]);

    // Lobby status loading state
    useEffect(() => {
        if (gameSettingStatus === 'loading') {
            setLoading(true);
        } else if (gameSettingStatus === 'succeeded') {
            setLoading(false);
        } else if (gameSettingStatus === 'failed') {
            setLoading(false);
            if (gameSettingError?.error?.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [gameSettingStatus, gameSettingError, navigate]);

    const handleBackButton = () => {
        if (editSettings) {
            setEditSettings(false)
        } else {
            navigate(-1)
        }
    };

    const handleInputChange = (field: string, value: any) => {
        setErrorMessage('');
        const numericValue = typeof value === 'string'
            ? parseInt(value.replace(/,/g, ''), 10)
            : value;
        switch (field) {
            case 'startingFund':
                setStartingFund(numericValue);
                break;
            case 'payDay':
                setPayDay(numericValue);
                break;
            case 'tradeDuration':
                setTradeDuration(numericValue);
                break;
            default:
                break;
        }
    }

    // Function to handle confirm setting
    const handleConfirmSettings = () => {
        setErrorMessage(null);

        dispatch(
            updateGameSetting({
                gameId: gameId,
                startingFunds: startingFund || gameSetting.starting_funds,
                paydayIncrement: payDay || gameSetting.payday_increment,
                tradePhaseDuration: tradeDuration || gameSetting.trade_phase_duration,
            })
        ).unwrap().catch((error) => {
            setEditSettings(true)
            setErrorMessage(error?.error?.message);
        });
        setEditSettings(false)
    };

    // Handle to Start a Game button
    const handleStartGameButton = () => {
        setLoading(true)
        dispatch(startGame(gameId)).unwrap().then((response) => {
            setLoading(false)
            message.success('Game started successfully');
            navigate('/first-player')
        }).catch((error) => {
            setLoading(false)
            message.error(error?.message)
        });
    };

    return (
        <div className=''>
            <BackButton onClick={handleBackButton} back_class='back-setup' />
            <div className='colorHeading-bckbtn font-bagel portfolio-heading'>Game Setup</div>

            {/* <div className='account-heading'>
                <RefreshButton onClick={handleRefreshButton} />
            </div> */}
            <div className='flex stocks-container '>
                {/* <div className='main-heading font-bagel' style={{ marginTop: "15px" }}>Setup game</div> */}
                <div className='flex stockContainer' style={{ position: "relative" }}>

                    <div className='flex login-upper-container game-setup-container overflow' style={{ marginTop: "20px" }}>
                        <div className='border-blue'>
                            <div className='border-dark-blue'>
                                <div className='border-orange'>
                                    <div className='border-yellow'>
                                        <div className='border-inner-blue'>
                                            {editSettings ?
                                                <ConfirmSetup
                                                    startingFund={startingFund}
                                                    payDay={payDay}
                                                    tradeDuration={tradeDuration}
                                                    gameSetting={gameSetting}
                                                    setEditSettings={setEditSettings}
                                                    handleConfirmSettings={handleConfirmSettings}
                                                    handleInputChange={handleInputChange}
                                                    errorMessage={errorMessage} /> :
                                                <SetupSettingContainer editSettings={editSettings} setEditSettings={setEditSettings} gameSetting={gameSetting} loading={loading} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='margin-bottom'>
                            <Button buttonTitle='Start Game' onClick={handleStartGameButton} width={'205px'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSetupScreen