import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { image } from '../../../app/utils/common/image';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import { SelectRole, selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import { getLobby } from '../../../redux/Slices/lobbySlice';
import Loader from '../../atoms/Loader/Loader';
import Button from '../../atoms/Button/Button';
import './ChooseAvatars.scss'
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { getAvatars, selectAvatars, selectAvatarsStatus } from '../../../redux/Slices/AvailableAvatars';
import { selectAllAvatarsStatus } from '../../../redux/Slices/avatarsSlice';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';

const ChooseAvatars = () => {
    const [loading, setLoading] = useState(false);
    const [selectedAvatar, setSelectedAvatar] = useState<number | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch: AppDispatch = useDispatch();
    const avatars = useSelector(selectAvatars);
    const avatarStatus = useSelector(selectAvatarsStatus)
    const gameId = useSelector(selectGameId)
    const roleId = useSelector(selectRoleId)
    const joinRoleId = useSelector(selectRoleJoinId);
    const location = useLocation();
    const navigate = useNavigate()
    const { role } = location.state || {};

    useEffect(() => {
        dispatch(getAvatars(gameId)); // Fetch avatars based on game ID
    }, [dispatch, gameId]);

    useEffect(() => {
        if (avatarStatus === 'loading') {
            setLoading(true);
        } else if (avatarStatus === 'succeeded') {
            setLoading(false);
        } else if (avatarStatus === 'failed') {
            setLoading(false);
        }
    }, [avatarStatus]);
    const shouldShowButtons = () => {
        const role = joinRoleId !== undefined ? joinRoleId : roleId;  // Use roleId if joinRoleId is undefined
        return role === 2;
    };

    const handleAvatarSelect = (avatarId: number) => {
        setSelectedAvatar(avatarId);
        setLoading(true);
        // Dispatch SelectRole with both roleId and avatarId
        dispatch(SelectRole({ role: role, avatar_id: avatarId, gameId }))
            .then((action) => {
                const { success, error } = action.payload
                if (success) {
                    setLoading(false);
                    message.success(action.payload.message);

                    // Now, refetch the lobby to get the updated game_status
                    dispatch(getLobby(gameId)).unwrap().then((lobbyData: any) => {
                        const gameStatus = lobbyData?.game_status;
                        if (gameStatus === 1) {
                            navigate('/showdown-lobby');
                        } else if (gameStatus === 2) {
                            navigate('/stock-prices');
                        } else {
                            navigate('/showdown-lobby')
                        }
                    }).catch((error) => {
                        console.error('Failed to fetch lobby:', error);
                        message.error('Failed to fetch game status. Please try again.');
                    });
                    navigate('/showdown-lobby')
                } else if (error) {
                    const errorMessage = error.message;
                    if (error.message === "User already in this board") {
                        if (shouldShowButtons()) {
                            navigate('/stock-prices');
                        } else {
                            navigate('/showdown-lobby');
                        }
                    } else {
                        setErrorMessage(errorMessage);
                    }
                    setErrorMessage(errorMessage);
                    message.error(errorMessage);
                    setLoading(false);
                }
            }).catch((error) => {
                console.error('Failed to dispatch SelectRole:', error);
                setLoading(false);
                message.error('Something went wrong. Please try again.');
            });
    };

    // Handle Refresh Button to reload portfolio value data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAvatars(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    return (
        <div>
            {/* <div className='account-heading'>
                <BackButton onClick={handleBackButton} />
            </div> */}
            <div className='account-heading'>
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='flex login-container join-avatar' style={{ justifyContent: window.innerWidth > 750 ? "center" : "start" }}>
                <div className='main-heading font-bagel response-heading' >Choose your<br /> Avatar
                    {/* <span className="get-help-addOn-hover" >
                        <span className="help-image-logo" > <IoMdInformationCircleOutline size={20} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                        <span className="get-help-code-hover">Choose your role: Investor, Broker, or both. At least one player must be a Broker to update stock prices</span>
                    </span> */}
                </div>
                <div className='flex login-upper-container ' style={{ paddingBottom: "30px" }}>
                    <div className='border-blue'>
                        <div className='border-dark-blue'>
                            <div className='border-orange'>
                                <div className='border-yellow'>
                                    <div className='border-inner-blue'>
                                        <div className='joinGame-dimension flex'>
                                            {!loading ?
                                                <div className='joinGame-container flex' style={{ width: "75%" }}>
                                                    {/* <div className='note-response'>At least one player must be a Broker to continue <span className="get-help-addOn-hover" >
                                                        <span className="help-image-logo" > <IoMdInformationCircleOutline size={16} cursor='pointer' style={{ marginBottom: "-5px" }} color='#FFC107' /></span>
                                                        <span className="get-help-response-hover" style={{ height: "42px" }}>Choose your role: Investor, Broker, or both. At least one player must be a Broker to update stock prices</span>
                                                    </span>
                                                    </div> */}
                                                    <div className='chooseAvatars' >
                                                        {avatars?.data?.map((avatar: any) => {
                                                            return (
                                                                <div key={avatar.id} onClick={() => avatar.id !== undefined && handleAvatarSelect(avatar.id)}>
                                                                    <img src={avatar.image} alt={avatar.name} className='avatar-image' draggable="false"
                                                                        onContextMenu={(e) => e.preventDefault()} />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div> : <Loader height='280px' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseAvatars;