import { Select } from 'antd'
import React, { FC } from 'react'
import { OptionType } from '../../../app/utils/constants/Options';
import './SelectInput.scss'
import { IoMdInformationCircleOutline } from 'react-icons/io';
const { Option } = Select;

interface SelectInputProps {
    label: string
    value: any;
    onChange: (value: number | string) => void;
    options: OptionType[];
    info?: string;
    ShowInfo?: boolean;
    classNameInfo?: string;
    dropdownStyle?: any;
    optionStyle?: any;
    mode?: any;
    placeholder?: string
}

const SelectInput: FC<SelectInputProps> = (props) => {
    const { value, onChange, options, label, info, ShowInfo, classNameInfo, dropdownStyle, optionStyle, mode, placeholder } = props
    return (
        <div>
            <div className='input-instruct'>
                <div className='input-label'>{label}</div>
                {ShowInfo && <div className="get-help-addOn-hover">
                    <div className="help-image-logo" > <IoMdInformationCircleOutline cursor='pointer' /></div>
                    <div className={classNameInfo}>{info}</div>
                </div>}
            </div>
            <Select
                value={value}
                onChange={onChange}
                style={{ width: '100%' }}
                dropdownStyle={dropdownStyle}
                mode={mode === "multiple" ? mode : ""}
                placeholder={placeholder}
                showSearch={false}
            >
                {options.map(option => (
                    <Option key={option.value} value={option.value} style={optionStyle}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default SelectInput