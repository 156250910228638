import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from "./Slices/authSlice";
import { setupAxiosInterceptors } from "../app/helper/axiosHelper";
import ProfileSlice from "./Slices/ProfileSlice";
import JoinGameSlice from "./Slices/JoinGameSlice";
import lobbySlice from "./Slices/lobbySlice";
import GameSettingSlice from "./Slices/GameSettingSlice";
import StockSlice from "./Slices/StockSlice";
import PortfolioSlice from "./Slices/PortfolioSlice";
import TransactionSlice from "./Slices/TransactionSlice";
import TradeSlice from "./Slices/TradeSlice";
import SelectRoleSlice from "./Slices/SelectRoleSlice";
import StartGame from "./Slices/StartGame";
import ShowDownSlice from "./Slices/ShowDownSlice";
import AvailableAvatars from "./Slices/AvailableAvatars";
import MarketMayhemSlice from "./Slices/MarketMayhemSlice";
import avatarsSlice from "./Slices/avatarsSlice";

const persistConfig = {
  key: 'root',
  storage,
}

const combineReducer = combineReducers({
  auth: authSlice,
  profile: ProfileSlice,
  game: JoinGameSlice,
  lobby: lobbySlice,
  setting: GameSettingSlice,
  stock: StockSlice,
  portfolio: PortfolioSlice,
  transc: TransactionSlice,
  trade: TradeSlice,
  role: SelectRoleSlice,
  start: StartGame,
  showdwn: ShowDownSlice,
  avatars: AvailableAvatars,
  allavatars: avatarsSlice,
  market: MarketMayhemSlice
});

const persistedReducer = persistReducer(persistConfig, combineReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

setupAxiosInterceptors(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
