import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";

// Define types for your state and API responses
interface AvatarsState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: AvatarsState = {
    status: 'idle',
    error: null,
    data: null,
};

interface UpdateAvatars {
    username: any,
    email: any,
    avatarId: number | undefined,
}


// getProfile 
export const getAllAvatars = createAsyncThunk('allavatars/getAllAvatars', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.ALLAVATARS}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});

// getProfile 
export const updateProfile = createAsyncThunk('allavatars/updateProfile', async ({ username, email, avatarId }: UpdateAvatars, { rejectWithValue }) => {
    try {
        const response = await apiClient.put(`${BASE_URL}${ApiConstants.UPDATEPROFILE}`, {
            username: username,
            email: email,
            avatar_id: avatarId,
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the profile slice
const AvaliableAvatarsSlice = createSlice({
    name: 'allavatars',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllAvatars.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAllAvatars.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getAllAvatars.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllAvatars = (state: any) => state.allavatars.data;
export const selectAllAvatarsStatus = (state: any) => state.allavatars?.status;
export const selectAllAvatarsError = (state: any) => state.allavatars?.error;

export default AvaliableAvatarsSlice.reducer;