import React, { FC, useState } from 'react';
import { formatPrice } from '../../atoms/FormatPrice';
import TradeBuyContainer from '../TradeBuyContainer/TradeBuyContainer';
import './TradePlatformContainer.scss';

interface TradePlatformContainerProps {
    handleStockClick: any,
    portfolio: any,
    selectedStock: any,
    setSelectedStock: React.Dispatch<React.SetStateAction<any>>,
    gameId: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

const TradePlatformContainer: FC<TradePlatformContainerProps> = (props) => {
    const { handleStockClick, portfolio, selectedStock, setSelectedStock, gameId, setLoading } = props
    const [tradeType, setTradeType] = useState<number | null>(null);

    // Function to handle trade type selection
    const handleTradeTypeChange = (port: any, type: number) => {
        setSelectedStock(port);  // Set the selected stock
        handleStockClick(port);
        setTradeType(type);  // Set trade type: 0 for Buy, 1 for Sell
    };

    return (
        <div className='prices-container por-prices-container'>
            {/* If no stock is selected, show all stocks */}
            {!selectedStock && portfolio?.portfolio_data.map((port: any) => (
                <div key={port.name} className='player-prf-conatiner portfolio-height'>
                    <img
                        src={port?.image}
                        className='company-profile'
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()}
                    />
                    <div className='pricetext-container' style={{ width: "100%" }}>
                        <div className='text-container'>
                            <div className='text-name'>{port.name}</div>
                            <div className='text-subname'>Qty: {port.total_quantity === 0 ? '0' : formatPrice(port.total_quantity)} | CP: {formatPrice(port.current_price)}</div>
                        </div>
                        <div className='text-container-trade'>
                            <div className={`buy-button ${(portfolio.orderbook_status !== 0 && portfolio.current_balance !== 0) ? '' : 'buy-disabled'}`}
                                onClick={
                                    (portfolio.orderbook_status !== 0 && portfolio.current_balance !== 0) ? () => handleTradeTypeChange(port, 0) : () => { }}>
                                <div className='text-buy'>BUY</div>
                            </div>
                            <div className={`sell-button ${portfolio.orderbook_status !== 0 && port.total_quantity !== 0 ? '' : 'buy-disabled'}`}
                                onClick={
                                    (portfolio.orderbook_status !== 0 && port.total_quantity !== 0) ? () => handleTradeTypeChange(port, 1) : () => { }}>
                                <div className='text-buy'>SELL</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* If a stock is selected, show only the selected stock and its TradeBuyContainer */}
            {selectedStock && (
                <>
                    <div className='player-prf-conatiner portfolio-height'>
                        <img
                            src={selectedStock?.image}
                            className='company-profile'
                            draggable="false"
                            onContextMenu={(e) => e.preventDefault()}
                        />
                        <div className='pricetext-container' style={{ width: "100%" }}>
                            <div className='text-container'>
                                <div className='text-name'>{selectedStock.name}</div>
                                <div className='text-subname'>Qty: {selectedStock.total_quantity === 0 ? '0' : formatPrice(selectedStock.total_quantity)}</div>
                            </div>
                            <div className='text-container-trade'>
                                <div className={`buy-button ${tradeType === 0 ? ' text-buy-border' : 'buy-disabled'}`}>
                                    <div className='text-buy'>BUY</div>
                                </div>
                                <div className={`sell-button ${selectedStock.total_quantity === 0 ? 'disabled' : ''} ${tradeType === 1 ? 'text-sell-border' : 'disabled'}`} >
                                    <div className='text-buy'>SELL</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Show the TradeBuyContainer for the selected stock */}
                    <TradeBuyContainer port={selectedStock} gameId={gameId} tradeType={tradeType} setSelectedStock={setSelectedStock} setLoading={setLoading} />
                </>
            )}
        </div>
    )
}

export default TradePlatformContainer;