export const startingFundsOptions = [
    { value: 500000, label: '₹5,00,000' },
    { value: 700000, label: '₹7,00,000' },
    { value: 1000000, label: '₹10,00,000' },
];

export const payDayOptions = [
    { value: 100000, label: '₹1,00,000' },
    { value: 300000, label: '₹3,00,000' },
    { value: 500000, label: '₹5,00,000' },
];

export const tradePhaseOptions = [
    { value: 60, label: '60 seconds' },
    { value: 75, label: '75 seconds' },
    { value: 120, label: '120 seconds' },
    { value: 180, label: '180 seconds' },
];

export const percentageMayhem = [
    { value: -1, label: '-1%' },
    { value: -2, label: '-2%' },
    { value: -3, label: '-3%' },
    { value: -4, label: '-4%' },
    { value: -5, label: '-5%' },
    { value: -6, label: '-6%' },
    { value: -9, label: '-9%' },
    { value: -11, label: '-11%' },
    { value: -12, label: '-12%' },
    { value: -14, label: '-14%' },
    { value: -15, label: '-15%' },
    { value: 1, label: '1%' },
    { value: 3, label: '3%' },
    { value: 4, label: '4%' },
    { value: 6, label: '6%' },
    { value: 7, label: '7%' },
    { value: 8, label: '8%' },
    { value: 9, label: '9%' },
    { value: 12, label: '12%' },
    { value: 13, label: '13%' },
    { value: 16, label: '16%' },


];

export const CompanyMayhem = [
    { value: 'BullionBank', label: 'Bullion Bank' },
    { value: 'Urban', label: 'Urban Realty' },
    { value: 'RailConnect', label: 'RailConnect' },
];

export interface OptionType {
    value: number | string; // or whatever type you expect
    label: string;
}