import React, { FC } from 'react';
import Input from '../../atoms/Input/Input';
import Loader from '../../atoms/Loader/Loader';
import './AccountContainer.scss';

interface AccountContainerProps {
    profile: any,
    loading: boolean,
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    name: string,
    email: string
}

const AccountContainer: FC<AccountContainerProps> = ({ profile, loading, handleInputChange, name, email }) => {

    return (
        <div>
            <div className='account-border'>
                {!loading ? <div className='account-container flex'>
                    <div style={{ width: "100%", zIndex: "100" }}>
                        <Input
                            label='Name'
                            placeholder='Enter Your Name'
                            type="name"
                            id="name"
                            name="name"
                            value={name || profile?.username || ''}
                            onChange={handleInputChange} />
                    </div>
                    <div style={{ width: "100%", zIndex: "100" }}>
                        <Input
                            label='Phone Number'
                            placeholder='Enter Your Phone Number'
                            type="tel"
                            id="tel"
                            name="phone-no"
                            value={`${profile?.country_code}${profile?.phone_number}`}
                            disabled={true}
                            onChange={() => { }} />

                    </div>
                    <div style={{ width: "100%", zIndex: "100" }}>
                        <Input
                            label='Email'
                            placeholder='Enter Your Email'
                            type="email"
                            id="email"
                            name="email"
                            value={email || profile?.email || ''}
                            onChange={handleInputChange} />
                    </div>
                </div> : <div className="flex items-center justify-center account-container">
                    <Loader height={'320px'} />
                </div>}
            </div>
        </div>
    )
}

export default AccountContainer