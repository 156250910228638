import React from 'react'

interface KeypadButtonProps {
    label: string;
    onPress: () => void;
    isOperator?: boolean;
    style?: any
}

const KeypadButton: React.FC<KeypadButtonProps> = ({ label, onPress, isOperator, style }) => {
    return (
        <div className='button-keypad'>
            <button className={`button ${isOperator ? 'operatorButton' : ''}`} onClick={onPress} style={style}>
                {label}
            </button>
        </div>

    );
};

export default KeypadButton