import React, { FC } from 'react'
import { IoRefreshCircleSharp } from 'react-icons/io5';


interface RefreshButtonProps {
    onClick: VoidFunction
}

const RefreshButton: FC<RefreshButtonProps> = ({ onClick }) => {
    return (
        <div className='refresh-button'>
            <div onClick={() => { }}>
                <IoRefreshCircleSharp size={40} color='#fff' onClick={onClick} />
            </div>
        </div>
    )
}

export default RefreshButton