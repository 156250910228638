import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice'
import { AppDispatch } from '../../../redux/store'
import { getPortfolio, selectAllPortfolio, selectPortfolioError, selectPortfolioStatus } from '../../../redux/Slices/PortfolioSlice'
import { selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice'
import { formatPrice } from '../../atoms/FormatPrice'
import Loader from '../../atoms/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import RefreshButton from '../../atoms/RefreshButton/RefreshButton'
import BackButton from '../../atoms/BackButton/BackButton'
import BottomSheet from '../../molecules/BottomSheet/BottomSheet'
import TradePlatformContainer from '../../molecules/TradePlatformContainer/TradePlatformContainer';
import { IoRefreshCircleSharp } from 'react-icons/io5';
import { image } from '../../../app/utils/common/image'
import { TbHelpOctagonFilled } from "react-icons/tb";
import HelpContainer from '../../molecules/HelpContainer/HelpContainer'
import OutsideClickHandler from 'react-outside-click-handler'
import { IoMdHelpCircleOutline } from "react-icons/io";

interface TradeScreenProps {
    secondsLeft: number | null,
    helpShow: boolean,
    setHelpShow: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: VoidFunction,
}

const TradeScreen: FC<TradeScreenProps> = (props) => {
    const { secondsLeft, helpShow, setHelpShow, handleClose } = props
    const [selectedStock, setSelectedStock] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate()
    const dispatch: AppDispatch = useDispatch();
    const gameId = useSelector(selectGameId)
    const portfolio = useSelector(selectAllPortfolio);
    const portfolioStatus = useSelector(selectPortfolioStatus)
    const portfolioError = useSelector(selectPortfolioError);
    const roleId = useSelector(selectRoleId);
    const joinRoleId = useSelector(selectRoleJoinId);

    const handleStockClick = (stock: any) => {
        if (joinRoleId === 2 && roleId === 2) {
            setSelectedStock(stock);
        }
    };

    useEffect(() => {
        dispatch(getPortfolio(gameId)); // Dispatch Get Profile action
    }, [dispatch]);

    useEffect(() => {
        if (portfolioStatus === 'loading') {
            setLoading(true);
        } else if (portfolioStatus === 'succeeded') {
            setLoading(false);
        } else if (portfolioStatus === 'failed') {
            setLoading(false);
            if (portfolioError?.error.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [portfolioStatus]);

    const handleBackButton = () => {
        setSelectedStock(null);
        // navigate(-1)
    }
    // Handle Refresh Button to reload trade data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getPortfolio(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }
    
    return (
        <div className='padding-bottom'>
            <div className='account-heading'>
                {/* {selectedStock && <BackButton onClick={handleBackButton} buttonHeading='Trade Hub' className='colorHeading-backbtn font-bagel' />} */}
                {selectedStock && <div style={{ position: "relative", width: "100%", zIndex: "100" }} >
                    <div className='trade-back-button'>
                        <div style={{ marginBottom: "9px" }}>
                            <img src={image.BACKBUTTON} onClick={handleBackButton} />
                        </div>
                        <div>
                            <div className='trade-colorHeading-backbtn font-bagel'>Trade Hub</div>
                        </div>
                    </div>
                </div>}
                {!selectedStock && <RefreshButton onClick={handleRefreshButton} />}
                {selectedStock && <div style={{ position: "relative", zIndex: '1' }} onClick={handleRefreshButton}>
                    <div className='refresh-button-trade'>
                        <IoRefreshCircleSharp size={40} color='#fff' />
                    </div>
                </div>}
            </div>
            {!selectedStock && <div className='timer-heading'>
                <div className='colorHeading-bckbtn font-bagel portfolio-heading' style={{ paddingTop: "0px" }}>Trade Hub </div>
                {secondsLeft && secondsLeft > 0 && <div className='seconds-left'>{secondsLeft} Sec</div>}
            </div>}

            <div className='flex stocks-container' style={{ marginTop: selectedStock ? "100px" : "" }}>
                <div className='flex stockContainer' style={{ gap: "20px", position: "relative" }}>
                    {!selectedStock && <div className='help-btn help-trade-btn' onClick={() => { setHelpShow(!helpShow) }}>
                        <IoMdHelpCircleOutline color='#fff' size={28} cursor={'pointer'} />
                    </div>}
                    {helpShow && <OutsideClickHandler onOutsideClick={handleClose}>
                        <HelpContainer setHelpShow={setHelpShow} />
                    </OutsideClickHandler>}
                    <div className='portfolio-total-bg'>
                        <div className='total-pl'>
                            <div className='total-pl-container'>
                                <div className='total-text'>Cash Balance</div>
                                {!loading && <div className='portfolio-total'>{portfolio?.current_balance === 0 ? portfolio?.current_balance : formatPrice(portfolio?.current_balance)}</div>}
                            </div>
                        </div>
                    </div>

                    <div className='account-border'>
                        <div className={`account-container flex ${!selectedStock ? 'trade-container' : 'trade-container-selc'} `}>
                            {!loading ? <TradePlatformContainer handleStockClick={handleStockClick} portfolio={portfolio} selectedStock={selectedStock} setSelectedStock={setSelectedStock} gameId={gameId} setLoading={setLoading} /> : <Loader height='585px' />}
                        </div>
                    </div>
                </div>
                {/* <BottomSheet /> */}
            </div>
        </div>
    )
}

export default TradeScreen