import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import { image } from '../../../app/utils/common/image';
import './SplashScreenEnd.scss';

const SplashScreenEnd = () => {
  const navigate = useNavigate();

  return (
    <div className='splash-end'>
      <div className='flex welcome-screen'>
        <img src={window.innerWidth > 750 ? image.WEBLOGO : image.LOGOBGCOLOR} className='splash-bg-end' draggable="false"
          onContextMenu={(e) => e.preventDefault()} />
        {/* <div>Welcome</div> */}
      </div>
      <div className='end-container'>
        <div className='splash-end-container'>
          {/* <Button buttonTitle='Profile' onClick={() => { navigate('/account-details') }} className={'splash-width-button'} /> */}
          <Button buttonTitle="Play 'The Showdown'" onClick={() => { navigate('/showDown') }} className={'splash-width-button'} />
          <Button buttonTitle='Shop' onClick={() => { }} className={'splash-width-button'} />
        </div>
      </div>
    </div>
  )
}

export default SplashScreenEnd