import React from 'react';
import { image } from '../../../app/utils/common/image';
import './SplashScreen.scss'

const SplashScreen = () => {
  return (
    <div className=''>
      <div className='flex splash-height'>
        <img src={window.innerWidth > 750 ? image.WEBLOGO : image.LOGOBGCOLOR} className='splash-bg' draggable="false"
          onContextMenu={(e) => e.preventDefault()} />
      </div>
    </div>
  )
}

export default SplashScreen