import React, { useEffect, useState } from 'react';

import { image } from '../../../app/utils/common/image';
import { useNavigate } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import POINT from '../../../assets/images/point.svg'
import './LandingScreen.scss';
import BackButton from '../../atoms/BackButton/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, selectAllProfile, selectProfileStatus } from '../../../redux/Slices/ProfileSlice';
import { AppDispatch } from '../../../redux/store';
import Loader from '../../atoms/Loader/Loader';

const LandingScreen = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const profile = useSelector(selectAllProfile);
    const profileStatus = useSelector(selectProfileStatus);

    const handleBackButton = () => {
        navigate('/profile')
    }

    useEffect(() => {
        dispatch(getProfile()); // Dispatch Get Profile action
    }, []);

    // Profile status loading state
    useEffect(() => {
        if (profileStatus === 'loading') {
            setLoading(true);
        } else if (profileStatus === 'succeeded') {
            setLoading(false);
        }
    }, [profileStatus]);

    return (
        <div>
            <BackButton onClick={handleBackButton} />
            <div className='flex login-container' style={{ height: "100vh", overflowY: "hidden" }}>
                <img src={window.innerWidth > 750 ? image.WEBLOGO : image.LOGOBGCOLOR} className='landing-bg-profile' draggable="false"
                    onContextMenu={(e) => e.preventDefault()} />
                {!loading ? <div>
                    <div className='flex landing-flex' >
                        <img src={profile.profile_image ? profile.profile_image : image.INVESTORAVATAR} className='landing-profile' draggable="false"
                            onContextMenu={(e) => e.preventDefault()} />
                        <div className='profile-heading profile-landing-heading' style={{}}>
                            <div>Welcome to</div>
                            <img src={image.SHOWDOWNWHITE} className='showdown-image' />
                            {/* <div>The Showdown!</div> */}
                        </div>
                    </div>
                    <div className='profile-heading landing-description'>
                        <img src={POINT} />
                        <div>This web-app is designed to work with the
                            physical board game. Already set up?
                            Let's Get Started!</div>
                    </div>
                    {/* <div className='profile-heading landing-description'>
                        <img src={POINT} />
                        <div>Prepare for the Ultimate Challenge!
                            This web app is your guide through the
                            ultimate showdown. Are you set up?</div>
                    </div> */}
                    <div className='flex buttons-profile' style={{ paddingBottom: "15px" }}>
                        <Button buttonTitle="Continue" onClick={() => { navigate('/showDown') }} className={'profile-width-button'} />
                    </div>
                    <div className='profile-heading landing-description'>
                        {/* <img src={POINT} /> */}
                        <div style={{ paddingLeft: "25px" }}>Don't have the board yet? Click below to
                            grab your board and join the fun!</div>
                    </div>
                    <div className='flex buttons-profile '>
                        <Button buttonTitle='Buy "The Showdown"' onClick={() => { }} className={'profile-width-button btn-landing'} />
                    </div>
                </div> : <Loader white />}

            </div>
        </div>

    )
}

export default LandingScreen