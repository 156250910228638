import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { ApiConstants } from "../../app/utils/apis/ApiConstants";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface ProfileState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: ProfileState = {
    status: 'idle',
    error: null,
    data: null,
};


// getProfile 
export const getProfile = createAsyncThunk('profile/getProfile', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}${ApiConstants.GETPROFILE}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the profile slice
const profileSlices = createSlice({
    name: 'profile',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
                state.error = null;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAllProfile = (state: any) => state.profile.data;
export const selectProfileStatus = (state: any) => state.profile?.status;

export default profileSlices.reducer;