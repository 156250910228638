import React from 'react';
import { image } from '../../../app/utils/common/image';
import { useLocation, useNavigate } from 'react-router-dom';
import './BottomSheet.scss';
import { useSelector } from 'react-redux';
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import { selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';

const BottomSheet = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const roleId = useSelector(selectRoleId);
    const joinRoleId = useSelector(selectRoleJoinId);
    const roleIdStock = joinRoleId !== undefined ? joinRoleId : roleId;

    return (
        <div className='bottomsheet-container'>
            <div className={`${roleIdStock === 1 ? 'bottomSheet-brok' : 'bottomSheet'}`}>
                <div className='flex bottom-btns' onClick={() => { navigate('/stock-prices') }}>
                    <img src={location.pathname === '/stock-prices' || location.pathname === '/price-updates' ? image.STOCKSELCBTN : image.STOCKTABBTN} className='trade-btn'
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()} />
                    <div className='bottom-text'>Market Monitor</div>
                </div>
                {roleIdStock !== 1 && <div className='flex bottom-btns' onClick={() => { navigate('/portfolio') }}>
                    <img src={location.pathname === '/portfolio' ? image.PORTFOLIOSELCBTN : image.PORTFOLIOTABBTN} className='trade-btn'
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()} />
                    <div className='bottom-text'>Portfolio</div>
                </div>}
                {roleIdStock !== 1 && <div className='flex bottom-btns' onClick={() => { navigate('/trade') }}>
                    <img src={location.pathname === '/trade' ? image.TRADESELCBTN : image.TRADETABBTN} className='trade-btn'
                        draggable="false"
                        onContextMenu={(e) => e.preventDefault()} />
                    <div className='bottom-text'>Trade Hub</div>
                </div>}
                <div className='flex bottom-btns' onClick={() => { navigate('/transaction') }}>
                    <div className={location.pathname !== '/transaction' ? 'history-bg' : 'history-linear-bg'}>
                        <img src={location.pathname === '/transaction' ? image.TRANSCTABBTN : image.TRANSCSELCBTN} className='trade-btn'
                            draggable="false"
                            onContextMenu={(e) => e.preventDefault()} />
                    </div>
                    <div className='bottom-text'>History</div>
                </div>
            </div>
        </div>
    )
}

export default BottomSheet