import React, { FC, useEffect, useRef, useState } from 'react'
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStock, payday, selectAllStock, selectMMExist, selectStockError, selectStockStatus } from '../../../redux/Slices/StockSlice';
import { selectGameId, selectRoleJoinId } from '../../../redux/Slices/JoinGameSlice';
import { selectRoleId } from '../../../redux/Slices/SelectRoleSlice';
import { updateGameOrderBook } from '../../../redux/Slices/GameSettingSlice';
import Loader from '../../atoms/Loader/Loader';
import Button from '../../atoms/Button/Button';
import StockPricesContainer from '../../molecules/StockPricesContainer/StockPricesContainer';
import RefreshButton from '../../atoms/RefreshButton/RefreshButton';
import { confirmModalProps } from '../../../app/utils/interface/confirm';
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';
import { selectMarketMayhem } from '../../../redux/Slices/MarketMayhemSlice';
import { TbHelpOctagonFilled } from "react-icons/tb";
import HelpContainer from '../../molecules/HelpContainer/HelpContainer';
import OutsideClickHandler from 'react-outside-click-handler';
import { IoMdHelpCircleOutline } from "react-icons/io";
import './StockPricesScreen.scss';

interface StockPricesScreenProps {
    handleInitiateTradeButton: any,
    orderBookStatus: number,
    secondsLeft: number | null,
    loadingTrade: boolean,
    setLoadingTrade: React.Dispatch<React.SetStateAction<boolean>>,
    helpShow: boolean,
    setHelpShow: React.Dispatch<React.SetStateAction<boolean>>,
    handleClose: VoidFunction
}

const StockPricesScreen: FC<StockPricesScreenProps> = (props) => {
    const { handleInitiateTradeButton, orderBookStatus, secondsLeft, loadingTrade, setLoadingTrade, helpShow, setHelpShow, handleClose } = props
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const stockPrices = useSelector(selectAllStock);
    const stockPricesStatus = useSelector(selectStockStatus);
    const stockPricesError = useSelector(selectStockError);
    const gameId = useSelector(selectGameId);
    const roleId = useSelector(selectRoleId);
    const joinRoleId = useSelector(selectRoleJoinId);
    const stockMMExist = useSelector(selectMMExist);
    
    const shouldShowButtons = () => {
        const role = joinRoleId !== undefined ? joinRoleId : roleId;  // Use roleId if joinRoleId is undefined
        return role !== 2;
    };
    const roleIdStock = joinRoleId !== undefined ? joinRoleId : roleId;
    // useEffect(() => {
    //     dispatch(getGameSetting(gameId));
    // }, []);

    useEffect(() => {
        if (gameId) {
            dispatch(getAllStock(gameId)); // Fetch stock prices on screen load or gameId change
        }
        dispatch(updateGameOrderBook({ gameId, orderbook_status: orderBookStatus }))
    }, [dispatch, gameId]);

    // Stock Prices status loading state
    useEffect(() => {
        if (stockPricesStatus === 'loading') {
            setLoading(true);
        } else if (stockPricesStatus === 'succeeded') {
            setLoading(false);
        } else if (stockPricesStatus === 'failed') {
            setLoading(false);
            if (stockPricesError?.error.message === 'Game already completed.') {
                navigate('/profile')
            }
        }
    }, [stockPricesStatus]);

    const handlePaydayButton = () => {
        setConfirmModal(false)
        setLoading(true);
        dispatch(payday(gameId)).unwrap().then((response) => {
            setLoading(false)
            message.success(response.message);
            dispatch(getAllStock(gameId))
        }).catch((error) => {
            console.error("Payday error:", error);
        });
        setLoading(false);
    };

    const handleStockClick = (stock: any) => {
        if (shouldShowButtons()) {
            navigate('/price-updates', { state: { stock } }); // Pass stock details
        }
    };

    // Handle Refresh Button to reload stock data
    const handleRefreshButton = () => {
        setLoading(true);
        dispatch(getAllStock(gameId))
            .unwrap()
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const confirmModalProps: confirmModalProps = {
        text: "Are you sure you want to give payday?",
        confirmModal,
        setConfirmModal,
        buttons: ['Yes', 'No'],
        onPressPositive: handlePaydayButton,
        onPressNegative: () => { setConfirmModal(false) }
    };

    return (
        <div className='login-container padding-bottom'>
            <div className='account-heading'>
                {/* <BackButton onClick={handleBackButton} /> */}
                <RefreshButton onClick={handleRefreshButton} />
            </div>
            <div className='flex stocks-container'>
                <div className='flex stockContainer' style={{ position: "relative" }}>
                    <div className='help-btn' onClick={() => { setHelpShow(!helpShow) }}>
                        <IoMdHelpCircleOutline color='#fff' size={28} cursor={'pointer'} />
                    </div>
                    {helpShow && <OutsideClickHandler onOutsideClick={handleClose}>
                        <HelpContainer setHelpShow={setHelpShow} />
                    </OutsideClickHandler>}
                    <div className='timer-heading'>
                        {/* <div className='' onClick={() => { navigate('/account-details') }}>profile</div> */}
                        <div className='colorHeading-bckbtn font-bagel portfolio-heading'>{roleIdStock === 2 ? 'Market Monitor' : 'Monitor & Action hub'}</div>
                        {secondsLeft && secondsLeft > 0 && <div className='seconds-left'>{secondsLeft} Sec</div>}
                    </div>
                    {/* <div className='colorHeading-bckbtn font-bagel portfolio-heading' style={{ fontSize: "45px" }}>Stock Prices</div> */}
                    <div className='flex login-upper-container stock-setup-container'>
                        <div className='border-blue'>
                            <div className='border-dark-blue'>
                                <div className='border-orange'>
                                    <div className='border-yellow'>
                                        <div className='border-inner-blue'>
                                            <div className='stock-dimension flex'>
                                                {!loading ? <StockPricesContainer stockPrices={stockPrices} handleStockClick={handleStockClick} /> : <div>
                                                    <Loader height='250px' /></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {shouldShowButtons() && <div className='stock-prices-btns'>
                            {orderBookStatus === 0 && <Button buttonTitle='Trade Phase' onClick={handleInitiateTradeButton} className={'trade-width-button'} />}
                            {orderBookStatus === 1 && <Button buttonTitle={`${!loadingTrade ? `${secondsLeft} Sec` : "..."}`} onClick={() => { }} className={'trade-width-button'} />}
                            <Button buttonTitle='Payday' onClick={() => { setConfirmModal(true) }} className={'trade-width-button'} />
                            <Button buttonTitle='Showdown' onClick={() => { navigate('/portfolio-showdown') }} className={'trade-width-button'} />
                        </div>}
                        {
                            (roleIdStock === 1 || roleIdStock === 3) &&
                            <div className='market-btn'>
                                <Button
                                    buttonTitle='Market Mayhem'
                                    onClick={() => { navigate('/market-mayhem') }}
                                    className='market-width-button'
                                />
                            </div>

                        }
                        {(roleIdStock === 2) && stockMMExist === 1 &&
                            < div className='market-btn'>
                                <Button
                                    buttonTitle='Market Mayhem'
                                    onClick={() => { navigate('/market-mayhem') }}
                                    className='market-width-button'
                                />
                            </div>
                        }

                    </div>
                </div>
            </div>
            {confirmModal && <ConfirmModal confirmModalProps={confirmModalProps} />}
        </div >
    )
}

export default StockPricesScreen