export const ApiConstants = {
    SENDOTP: '/api/auth/send-otp',
    LOGIN: '/api/auth/login',
    RESENDOTP: '/api/auth/resend-otp',
    REFRESHTOKEN: '/api/auth/refresh-access-token',
    REGISTER_OTP: '/api/auth/register/sendotp',
    LOGOUT: '/api/auth/logout',
    REGISTER: '/api/auth/register',
    GETPROFILE: '/api/user/profile',
    JOINGAME: '/api/game/join',
    UPDATEPROFILE: '/api/user/profile',
    ALLAVATARS:'/api/avatars'
}