import React, { FC } from 'react'
import COMPANYVI from '../../../assets/images/Company-Vi.svg';
import COMPANYYES from '../../../assets/images/Company-Yes.svg';
import COMPANYDLF from '../../../assets/images/Company-Dlf.svg';
import COMPANYIRCTC from '../../../assets/images/Company-Irctc.svg';
import COMPANYTATA from '../../../assets/images/Company-Tata.svg';
import COMPANYNESTLE from '../../../assets/images/Company-Nestle.svg';
import { formatPrice } from '../../atoms/FormatPrice';

interface StockPricesContainerProps {
    stockPrices: any,
    handleStockClick: any
}
const StockPricesContainer: FC<StockPricesContainerProps> = (props) => {
    const { stockPrices, handleStockClick } = props;
    return (
        <div className='prices-container'>
            {stockPrices?.stocks_data?.map((stock: any) => {
                return (
                    <div className='player-prf-conatiner company-height' onClick={() => handleStockClick(stock)} style={{ cursor: "pointer" }}>
                        <img src={stock?.image}
                            className='company-profile' draggable="false"
                            onContextMenu={(e) => e.preventDefault()} />
                        <div className='pricetext-container'>
                            <div className='pricetext-subname'>{stock?.name}</div>
                            <div className='pricetext-name'>{formatPrice(stock.current_price)}</div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default StockPricesContainer