import React, { FC } from 'react'
import { image } from '../../../app/utils/common/image';
import COMPANYVI from '../../../assets/images/Company-Vi.svg';
import COMPANYYES from '../../../assets/images/Company-Yes.svg';
import COMPANYDLF from '../../../assets/images/Company-Dlf.svg';
import COMPANYIRCTC from '../../../assets/images/Company-Irctc.svg';
import UPARROW from '../../../assets/images/up-arrow.svg';
import DOWNARROW from '../../../assets/images/down-arrow.svg';
import PAYDAY from '../../../assets/images/PayDay-logo.svg';
import Loader from '../../atoms/Loader/Loader';
import './TransactionContainer.scss';
import { formatPrice } from '../../atoms/FormatPrice';

interface TransactionContainerProps {
    transaction: any,
    loading: boolean
}

const TransactionContainer: FC<TransactionContainerProps> = ({ transaction, loading }) => {
    const formatPriceTransc = (price: any) => {
        const parsedPrice = Math.abs(parseFloat(price));
        const roundedPrice = parsedPrice % 1 >= 0.5 ? Math.ceil(parsedPrice) : Math.floor(parsedPrice);
        const formattedPrice = roundedPrice.toLocaleString('en-IN');
        return formattedPrice
    };

    return (
        <>{
            !loading ? <div className='prices-container por-prices-container'>
                {transaction?.map((transc: any) => {
                    return (
                        <div className='player-prf-conatiner portfolio-height'>

                            {transc.type === "Price update" && <div className='pricetext-container' style={{ width: "100%", alignItems: "center", paddingLeft: "8px" }}>
                                <div className='text-container text-transc'>
                                    <div className='text-name'>Price Update</div>
                                </div>
                                <img src={transc?.image}
                                    className='company-profile' draggable="false"
                                    onContextMenu={(e) => e.preventDefault()} />
                                <div className='text-container-trade text-container-transc'>
                                    <div className='text-buy'><img src={transc.price.startsWith('-') ? DOWNARROW : UPARROW} className='green-arrow' draggable="false"
                                        onContextMenu={(e) => e.preventDefault()} /></div>
                                    <div className={`${transc.price.startsWith('-') ? 'text-red-percent' : 'text-percent'}`}>{formatPriceTransc(transc.price)}%</div>
                                </div>
                            </div>}
                            {transc.type === 'PAYDAY' &&
                                <div className='pricetext-container' style={{ width: "100%", alignItems: "center", paddingLeft: "8px" }}>
                                    <div className='text-container text-transc'>
                                        <div className='text-name'>PAYDAY</div>
                                    </div>
                                    <img src={PAYDAY} className='company-profile' draggable="false"
                                        onContextMenu={(e) => e.preventDefault()} />
                                    <div className='text-container-trade text-container-transc'>
                                        <div className='text-name'>{`+INR ${formatPriceTransc(transc.price)}`}</div>
                                    </div>
                                </div>
                            }
                            {transc.type === 'trade' &&
                                <div className='pricetext-container' style={{ width: "100%", alignItems: "center", paddingLeft: "8px" }}>
                                    <div className='text-container text-transc'>
                                        <div className='text-name'>{transc.trade_type === 1 ? 'Shares Sold' : 'Shares Bought'}</div>
                                    </div>
                                    <img src={transc?.image}
                                        className='company-profile' draggable="false"
                                        onContextMenu={(e) => e.preventDefault()} />
                                    <div className='text-container-transc'>
                                        <div className='text-name'>{formatPrice(transc.quantity)} Shares</div>
                                        <div className='text-subname'>CP: {formatPriceTransc(transc.price)}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div> : <Loader height='585px' />
        }  </>
    )
}

export default TransactionContainer