import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../app/helper/axiosInstance";
import { apiClient } from "../../app/helper/axiosHelper";

// Define types for your state and API responses
interface AvatarsState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    data: any;
}

// Define the initial state
const initialState: AvatarsState = {
    status: 'idle',
    error: null,
    data: null,
};


// getProfile 
export const getAvatars = createAsyncThunk('avatars/getAvatars', async (gameId: number, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`${BASE_URL}/api/game/${gameId}/avatars`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response ? error.response.data : error.message);
    }
});


// Create the profile slice
const AvaliableAvatarsSlice = createSlice({
    name: 'avatars',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAvatars.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getAvatars.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getAvatars.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
    }
});

// Selectors for accessing state
export const selectAvatars = (state: any) => state.avatars.data;
export const selectAvatarsStatus = (state: any) => state.avatars?.status;
export const selectAvatarsError = (state: any) => state.avatars?.error;

export default AvaliableAvatarsSlice.reducer;