import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './HelpContainer.scss';
import { TiHome } from "react-icons/ti";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa6";
import ConfirmModal from '../../atoms/confirmation-modal/confirmation-modal';
import { confirmModalProps } from '../../../app/utils/interface/confirm';

interface HelpContainerProps {
    setHelpShow: React.Dispatch<React.SetStateAction<boolean>>
}

const HelpContainer: FC<HelpContainerProps> = (props) => {
    const { setHelpShow } = props
    const navigate = useNavigate();
    const location = useLocation();
    const [confirmModal, setConfirmModal] = useState<boolean>(false);

    const pdfUrl = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

    const handleHomeBtn = () => {
        navigate('/home')
    }

    const confirmModalProps: confirmModalProps = {
        text: "Do you want to return Home?",
        confirmModal,
        setConfirmModal,
        buttons: ['Yes', 'No'],
        onPressPositive: handleHomeBtn,
        onPressNegative: () => { setConfirmModal(false) }
    };



    return (
        <div style={{ position: "relative" }}>
            <div className={location.pathname === '/stock-prices' ? 'help-container' : location.pathname === '/trade' ? 'help-container-trade' : 'help-container-transc'}>
                <div className='border-background-yellow'>
                    <div className='help-dimension flex'>
                        <div>
                            <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className='pdf-text help-conatienr-text'>
                                <FaFilePdf size={30} color='#FFC107' /> How to Play
                            </a>
                        </div>
                        <div onClick={() => { navigate('/role'); setHelpShow(false) }} style={{ cursor: "pointer" }} className='help-conatienr-text'><FaPeopleGroup size={30} color='#FFC107' />Player Roles</div>
                        <div onClick={() => { setConfirmModal(true);}} style={{ cursor: "pointer", gap: "6px" }} className='help-conatienr-text'><TiHome size={30} color='#FFC107' />Home</div>
                    </div>
                </div>
            </div>
            {confirmModal && <ConfirmModal confirmModalProps={confirmModalProps} />}

        </div>

    )
}

export default HelpContainer
