import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { resendOtp } from '../../../redux/Slices/authSlice';
import Button from '../../atoms/Button/Button';
import './VerifyLoginOtp.scss';
import { IoMdInformationCircleOutline } from 'react-icons/io';

interface VerifyLoginOtpProps {
    handleOtpChange: Function,
    otp: string[],
    setOtp: React.Dispatch<React.SetStateAction<string[]>>,
    handleVerifyOtp: Function,
    setActiveButton: React.Dispatch<React.SetStateAction<boolean>>,
    loading: boolean,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    otpId: string,
    errorMessage: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,

}

const VerifyLoginOtp: FC<VerifyLoginOtpProps> = (props) => {
    const { handleOtpChange, otp, setOtp, handleVerifyOtp, setActiveButton, errorMessage, loading, setLoading, otpId, setErrorMessage } = props;

    const dispatch: AppDispatch = useDispatch();
    const inputsRef = useRef<any>([]);
    const [countdown, setCountdown] = useState(40);

    useEffect(() => {
        let timer: any;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleKeyDown = (e: any, index: number) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleVerifyOtp(); // Trigger Verify OTP on Enter
        } else if (e.key === "Backspace") {
            if (otp[index] === "" && index > 0) {
                let finalIdex = index - 1;
                inputsRef.current[finalIdex].focus();
            }
            setActiveButton(false);
        }
    };

    const handleInputChange = (e: any, index: number) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === '') {
            setErrorMessage('');
            handleOtpChange(e, index);
            if (value !== '' && index < 3) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    // Handle to Resend otp
    const handleResendOtp = async () => {
        setLoading(true);
        if (countdown === 0) {
            setLoading(false);
            await dispatch(resendOtp({ otpId })); // Dispatch the resend OTP action
            setCountdown(40); // Reset the countdown after resend
            setOtp(["", "", "", ""]);
        }
    };

    return (
        <div>
            {!loading && <div className='verify-phone-container'>
                <div className='otp-container'>
                    <div className='input-instruct'>
                        <div className='input-label'>Verification Code (OTP)</div>
                        <div className="get-help-addOn-hover">
                            <div className="help-image-logo" > <IoMdInformationCircleOutline cursor='pointer' /></div>
                            <div className="get-help-add-on-hover">Enter the 4-digit OTP sent to your phone</div>
                        </div>
                    </div>
                    {/* <div className='input-label'>Verification Code(OTP)</div> */}
                    <div className='otp-container-input'>
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                className='search-input verify-input'
                                placeholder='-'
                                type="tel"
                                id="phone-no"
                                name="phone-no"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                ref={(el) => (inputsRef.current[index] = el)}
                                onWheel={(e: any) => e.target.blur()}
                            />
                        ))}
                    </div>
                    <div>
                        {errorMessage === 'The entered OTP is incorrect. Please try again.' && <div style={{ color: 'red', fontSize: '12px', marginLeft: '5px', paddingTop: "8px", fontWeight: '500' }}>The entered OTP is incorrect.</div>}
                    </div>
                    <div className='resend-container' >
                        <label className='resend-text'>Didn't get the code?
                            <span className=' resend-timer'>
                                {countdown > 0 ? (` Resend OTP in ${countdown} sec`) : <span className='cursor-pointer' onClick={handleResendOtp}> Resend OTP</span>}
                            </span>
                        </label>
                    </div>
                </div>
                <Button onClick={() => { handleVerifyOtp() }} buttonTitle='Verify' />
            </div>}
        </div>
    )
}

export default VerifyLoginOtp